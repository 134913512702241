import { Container } from 'inversify';
import { BusInjection, registers } from './helpers/registers';
import packageInfo from '../../../package.json';

import { queryDependecies } from './queries/queryDependencies';
import { bootstrapWithReact } from '@lookiero/messaging.js';
import { processManagerDependecies } from './processManagers/processManagerDependencies';
import { fromStringToSymbol } from './_data-transfrom/fromStringToSymbol';
import HttpEnvironmentFetcher from 'shared/infrastructure/environment/HttpEnvironmentFetcher';
import AxiosHttpClient from 'shared/infrastructure/delivery/AxiosHttpClient';
import Environment from 'shared/domain/environment/Environment';
import SentryLogger from 'core/infrastructure/domain/model/logger/SentryLogger';
import AxiosHttpClientInterceptor from 'core/infrastructure/delivery/AxiosHttpClientInterceptor';
import { commandDependecies } from './commands/commandDependecies';
import LocalStorage from 'shared/infrastructure/storage/LocalStorage';
import Authenticator from 'core/domain/model/employee/write/Authenticator';
import { inversifyInterfaces } from 'inversify-vanillajs-helpers';
import ConsoleLogger from 'core/infrastructure/domain/model/logger/ConsoleLogger';
import InMemoryNotificationRepository from 'core/infrastructure/persistence/notification/InMemoryNotificationRepository';
import ThrowErrorHandler from 'shared/application/error/throwError/ThrowErrorHandler';
import InMemoryErrorRepository from 'shared/infrastructure/persistence/error/InMemoryErrorRepository';

interface Bootstrap {
  (): Promise<Container>;
}

let container: Container;

const bootstrap: Bootstrap = async () => {
  if (container) {
    return container;
  }
  container = new Container();

  const { register, registerConstantValue, registerDynamicValue, busInjection } = registers({ container });

  registerConstantValue({ identifier: 'Console', value: window.console });
  registerConstantValue({ identifier: 'ApiUrl', value: 'https://jsonplaceholder.typicode.com' });

  register({ identifier: 'Logger', dependencies: ['Console'], classInjection: ConsoleLogger });
  register({ identifier: 'NotificationRepository', dependencies: [], classInjection: InMemoryNotificationRepository });

  register({ identifier: 'ErrorRepository', dependencies: [], classInjection: InMemoryErrorRepository });
  register({
    identifier: 'ThrowCoreErrorHandler',
    dependencies: ['ErrorRepository'],
    classInjection: ThrowErrorHandler,
  });

  const environmentFetcher = new HttpEnvironmentFetcher(new AxiosHttpClient());
  const environment = await environmentFetcher.fetch();

  registerDynamicValue({
    identifier: 'Environment',
    func: () => ({
      ...environment,
    }),
  });

  register({
    identifier: 'AxiosHttpClientInterceptor',
    dependencies: [],
    classInjection: AxiosHttpClientInterceptor,
  });

  registerDynamicValue({
    identifier: 'SentryLogger',
    func: (context) => {
      const environment = context.container.get<Environment>(fromStringToSymbol('Environment'));

      return new SentryLogger({
        environment: window.location.hostname,
        release: packageInfo.version,
        publicKey: environment.sentryPublicKey,
        projectId: environment.sentryProject,
      });
    },
  });

  const registerAxiosHttpClientWithAuth = (baseUrl: string) => {
    return (context: inversifyInterfaces.Context): AxiosHttpClient => {
      const interceptor = context.container.get<AxiosHttpClientInterceptor>(
        fromStringToSymbol('AxiosHttpClientInterceptor'),
      );
      const httpClient = new AxiosHttpClient(baseUrl);
      interceptor.config(httpClient.instance);
      return httpClient;
    };
  };

  registerDynamicValue({
    identifier: 'PickingHttpClient',
    func: registerAxiosHttpClientWithAuth(environment.pickingBackApi),
  });

  registerDynamicValue({
    identifier: 'ShippingHttpClient',
    func: registerAxiosHttpClientWithAuth(environment.shippingBackApi),
  });

  registerDynamicValue({
    identifier: 'WarehouseHttpClient',
    func: registerAxiosHttpClientWithAuth(environment.warehouseBackApi),
  });

  registerDynamicValue({
    identifier: 'ReturnHttpClient',
    func: registerAxiosHttpClientWithAuth(environment.returnsBackApi),
  });

  registerDynamicValue({
    identifier: 'CatalogHttpClient',
    func: registerAxiosHttpClientWithAuth(environment.catalogBackApi),
  });

  registerDynamicValue({
    identifier: 'PrinterHttpClient',
    func: registerAxiosHttpClientWithAuth(environment.printerServerApi),
  });

  registerDynamicValue({
    identifier: 'LocalStorage',
    func: () => {
      return new LocalStorage('warehouse_storage');
    },
  });

  registerDynamicValue({
    identifier: 'Authenticator',
    func: () => {
      const httpClient = new AxiosHttpClient();
      return new Authenticator(httpClient, environment.authApi);
    },
  });

  registerDynamicValue({
    identifier: 'GetTranslationEndpoint',
    func: (context) => {
      const {
        internationalization: { apiKey, endpoint },
      } = context.container.get<Environment>(fromStringToSymbol('Environment'));

      return (locale: string) => `${endpoint}/translations/${locale}?key=${apiKey}&no-folding=true`;
    },
  });

  const commands = await commandDependecies({ container });
  const queries = await queryDependecies({ container });
  const processManagers = await processManagerDependecies({ container });

  const { commandBus, eventBus, component } = bootstrapWithReact({
    queries,
    commands,
    processManagers,
  });

  registerConstantValue({ identifier: 'DomainEventBus', value: eventBus });
  registerConstantValue({ identifier: 'CommandBus', value: commandBus });
  registerConstantValue({ identifier: 'MessagingRootComponent', value: component });

  busInjection({ identifiyer: 'DomainEventBus', name: 'LoginEmployeeHandler', type: BusInjection.EVENT_BUS });
  busInjection({ identifiyer: 'DomainEventBus', name: 'LogoutEmployeeHandler', type: BusInjection.EVENT_BUS });
  busInjection({ identifiyer: 'DomainEventBus', name: 'CreateEmployeeHandler', type: BusInjection.EVENT_BUS });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'PrintLabelHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({ identifiyer: 'DomainEventBus', name: 'AddOrderHandler', type: BusInjection.EVENT_BUS });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'RemoveOrdersHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'SendPalletsHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'FindOrderReturnHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'InventoryReferenceHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'FinalizeInventoryHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'FinishOrderReturnHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'RemoveInventoryHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'RemoveRoutesHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'AssignPreparerHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'AssignReturnsPrinterHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'AssignRouteToTrolleyHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'AssignCachedRouteHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'AssignRouteTrolleyToPickerHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'PrintRouteDocumentsHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'PrintOrderReturnReferenceHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'CreateNotificationHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'RemoveNotificationHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'SendFinishedPalletHandler',
    type: BusInjection.EVENT_BUS,
  });
  busInjection({
    identifiyer: 'DomainEventBus',
    name: 'PalletSendFinishFailedListener',
    type: BusInjection.EVENT_BUS,
  });

  busInjection({
    identifiyer: 'CommandBus',
    name: 'PalletLabelsPrintedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'PalletsSentListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'OrdersRemovedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'InventoryFinalizedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'OrderReturnFinishedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'InventoryRemovedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'RoutesRemovedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'PrintPalletLabelsFailedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'SendPalletsFailedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'InventoryReferenceFailedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'PalletSendFinishedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'PalletSendFinishFailedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'RouteDocumentsPrintedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'OrderReturnReferencePrintedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'RouteAssignedToTrolleyListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'CachedRouteAssignedListener',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'CommandBus',
    name: 'CreateEmployeeWhenAuthenticated',
    type: BusInjection.COMMAND_BUS,
  });

  busInjection({
    identifiyer: 'CommandBus',
    name: 'AxiosHttpClientInterceptor',
    type: BusInjection.COMMAND_BUS,
  });
  busInjection({
    identifiyer: 'EmployeeReader',
    name: 'AxiosHttpClientInterceptor',
    type: BusInjection.EMPLOYEE_READER,
  });

  return container;
};

export { bootstrap, container };
