import { Command } from '@lookiero/messaging.js';

class AssignCachedRoute extends Command {
  public readonly orderId: string;
  public readonly routeForType: string;

  public constructor(orderId: string, routeForType: string) {
    super();
    this.orderId = orderId;
    this.routeForType = routeForType;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'AssignCachedRoute';
  }
}

export default AssignCachedRoute;
