const PREFIX = 'picking.';

const I18N_MESSAGES = {
  TABS_UNPREPARED_TROLLEY: 'tabs.unprepared_trolley',
  TABS_PREPARED_TROLLEY: 'tabs.prepared_trolley',

  UNPREPARED_TROLLEY_TABLE_COLUMN_CUSTOMER: 'unprepared_trolley.table_column_customer',
  UNPREPARED_TROLLEY_TABLE_COLUMN_PREPARATION_DATE: 'unprepared_trolley.table_column_preparation_date',
  UNPREPARED_TROLLEY_TABLE_COLUMN_COUNTRY: 'unprepared_trolley.table_column_country',
  UNPREPARED_TROLLEY_TABLE_COLUMN_ORDER: 'unprepared_trolley.table_column_order',
  UNPREPARED_TROLLEY_TABLE_COLUMN_GARMENTS: 'unprepared_trolley.table_column_garments',
  UNPREPARED_TROLLEY_TABLE_COLUMN_BOX_NUMBER: 'unprepared_trolley.table_column_box_number',

  UNPREPARED_TROLLEY_PRINTER_LINE_PLACEHOLDER: 'unprepared_trolley.printer_line_placeholder',
  UNPREPARED_TROLLEY_PRINTER_LINE_LABEL: 'unprepared_trolley.printer_line_label',
  UNPREPARED_TROLLEY_PRINT_ALL_ROUTE_DOCUMENTS: 'unprepared_trolley.print_all_button',
  UNPREPARED_TROLLEY_ASSIGN_ROUTE_TO_PREPARER: 'unprepared_trolley.assign_route_to_preparer_button',

  UNPREPARED_TROLLEY_PRINT_DOCUMENT_TYPE_PLACEHOLDER: 'unprepared_trolley.print_document_type_placeholder',
  UNPREPARED_TROLLEY_PRINT_DOCUMENT_TYPE_OPTION_COLLAGE: 'unprepared_trolley.print_document_type_option_collage',
  UNPREPARED_TROLLEY_PRINT_DOCUMENT_TYPE_OPTION_ORDER_SUMMARY:
    'unprepared_trolley.print_document_type_option_order_summary',
  UNPREPARED_TROLLEY_PRINT_DOCUMENT_TYPE_OPTION_NOTE: 'unprepared_trolley.print_document_type_option_note',

  UNPREPARED_TROLLEY_GENERIC_ERROR_GETTING_ROUTE_TITLE: 'unprepared_trolley.generic_error_getting_route_title',
  UNPREPARED_TROLLEY_GENERIC_ERROR_GETTING_ROUTE: 'unprepared_trolley.generic_error_getting_route',

  UNPREPARED_TROLLEY_ASSIGN_TROLLEY_LABEL: 'unprepared_trolley.assign_trolley_label',
  UNPREPARED_TROLLEY_ASSIGN_TROLLEY_PLACEHOLDER: 'unprepared_trolley.assign_trolley_placeholder',

  UNPREPARED_TROLLEY_ROUTE_REMOVED_MODAL_TITLE: 'unprepared_trolley.route_removed_modal_title',
  UNPREPARED_TROLLEY_ROUTE_REMOVED_MODAL_BODY: 'unprepared_trolley.route_removed_modal_body',
  UNPREPARED_TROLLEY_ROUTE_REMOVED_MODAL_OK_BUTTON: 'unprepared_trolley.route_removed_modal_ok_button',

  ROUTE_VIEW_NOT_FOUND_ERROR: 'unprepared_trolley.route_view_not_found_error',
  ROUTE_WITH_PREPARER_ERROR_TITLE: 'unprepared_trolley.route_with_preparer_error_title',
  ROUTE_WITH_PREPARER_ERROR: 'unprepared_trolley.route_with_preparer_error',
  ROUTE_NOT_FOUND_TO_PREPARE_ERROR: 'unprepared_trolley.route_not_found_to_prepare_error',
  ROUTE_NOT_FOUND_TO_MANUAL_ASSIGN: 'unprepared.route_not_found_to_manual_assign_error',
  CACHED_ROUTE_ASSIGNED_TO_PICKER: 'unprepared.manual_route_assigned_to_picker_error',
  ROUTE_NOT_FOUND_TO_PREPARE_ERROR_TITLE: 'unprepared_trolley.route_not_found_to_prepare_error_title',
  ROUTE_EXISTING_WITH_TROLLEY_ERROR: 'unprepared_trolley.other_route_exists_with_trolley_error',
  ROUTE_NOT_PREPARED_TO_ASSIGN_TROLLEY_ERROR: 'unprepared_trolley.route_not_prepared_to_assign_trolley_error',
  ROUTE_NOT_FOUND_TO_ASSIGN_TROLLEY_ERROR: 'unprepared_trolley.route_not_found_to_assign_trolley_error',
  ROUTE_NOT_FOUND_TO_PRINT_ERROR: 'unprepared_trolley.route_not_found_to_print_error',
  TROLLEY_BARCODE_BAD_FORMAT_ERROR: 'unprepared_trolley.trolley_barcode_bad_format_error',

  ORDER_VIEWS_NOT_FOUND_ERROR: 'unprepared_trolley.orders_not_found_error',

  PREPARED_TROLLEY_INPUT_SCANNER_TITLE: 'prepared_trolley.input_scanner_title',
  PREPARED_TROLLEY_INPUT_SCANNER_PLACEHOLDER: 'prepared_trolley.input_scanner_placeholder',
  ROUTE_WITH_TROLLEY_NOT_FOUND_ERROR: 'prepared_trolley.input_scanner_not_found_error',

  NOTIFICATION_ROUTE_DOCUMENTS_PRINTED: 'notification.route_documents_printed',
  NOTIFICATION_PRINT_ROUTE_DOCUMENTS_FAILED: 'notification.print_route_documents_failed',
  NOTIFICATION_PRINT_ROUTE_DOCUMENTS_FAILED_TITLE: 'notification.print_route_documents_failed_title',
  NOTIFICATION_ROUTE_ASSIGNED_TO_TROLLEY: 'notification.route_assigned_to_trolley',
  NOTIFICATION_CACHED_ROUTE_ASSIGNED: 'notification.manual_route_assigned',
};

export { PREFIX, I18N_MESSAGES };
