import OrderReferenceInvalidError from 'core/domain/model/order/OrderReferenceInvalidError';
import OrderReader from 'core/domain/model/order/read/OrderReader';
import OrderViewsNotFoundError from 'core/domain/model/order/read/OrderViewsNotFoundError';
import { OrderView } from 'core/domain/model/order/read/OrderView';
import HttpClient, { HttpClientResponseError, HttpErrorCodes } from 'shared/domain/delivery/HttpClient';
import { HttpWorkOrderView, httpWorkOrderViewToDomain } from './HttpWorkOrderView';

enum OrderStatus {
  PICKING = 'PICKING',
  PICKED = 'PICKED',
  PAUSED = 'PAUSED',
  RESERVED = 'RESERVED',
}

class HttpOrderReader implements OrderReader {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async findOrdersByBarcodes(barcodes: string[]): Promise<OrderView[]> {
    const queryParams = {
      barcode: barcodes,
      status: [OrderStatus.PICKING, OrderStatus.PICKED, OrderStatus.PAUSED],
    };

    return this.httpClient
      .get<HttpWorkOrderView[]>('/v3/workorders', queryParams)
      .then((orders) => orders.map((order) => httpWorkOrderViewToDomain(order)))
      .catch((error: HttpClientResponseError) => {
        if (error.status === HttpErrorCodes.BAD_REQUEST) {
          throw new OrderReferenceInvalidError();
        }

        throw error;
      });
  }

  getOrdersByPublicOrderId(publicOrderIds: string[]): Promise<OrderView[]> {
    const queryParams = {
      publicOrderId: publicOrderIds,
    };

    return this.httpClient.get<HttpWorkOrderView[]>('/workorders/v1', queryParams).then((orders) => {
      if (orders.length === 0) {
        throw new OrderViewsNotFoundError();
      }
      return orders
        .map((order) => httpWorkOrderViewToDomain(order))
        .sort((a, b) => (publicOrderIds.indexOf(a.publicOrderId) > publicOrderIds.indexOf(b.publicOrderId) ? 1 : -1));
    });
  }

  getUnsoldOrders(): Promise<OrderView[]> {
    const queryParams = {
      workOrderType: ['UNSOLD'],
      status: [OrderStatus.RESERVED],
    };

    return this.httpClient.get<HttpWorkOrderView[]>('/v3/workorders', queryParams).then((orders) => {
      if (orders.length === 0) {
        return [];
      }
      return orders.map((order) => httpWorkOrderViewToDomain(order));
    });
  }
}

export default HttpOrderReader;
