import React from 'react';
import '@lookiero/react-ui-kit/lib/styles/styles.css';
import { bootstrap } from 'core/config/bootstrap';
import Logger from 'core/domain/model/logger/Logger';
import { MessagingRoot } from '@lookiero/messaging.js';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import Root from 'ui/views/root/Root';
import Environment from 'shared/domain/environment/Environment';
import { fromStringToSymbol } from 'core/config/_data-transfrom/fromStringToSymbol';

import './ui/index.css';

/* istanbul ignore file */
(async () => {
  const container = await bootstrap();

  const rootContainer = document.getElementById('root') as HTMLElement;
  const root = ReactDOM.createRoot(rootContainer);

  root.render(
    <Root
      MessagingRootComponent={container.get<MessagingRoot>(fromStringToSymbol('MessagingRootComponent'))}
      logger={container.get<Logger>(fromStringToSymbol('Logger'))}
      environment={container.get<Environment>(fromStringToSymbol('Environment'))}
      getI18nEndpoint={container.get<(locale: string) => string>(fromStringToSymbol('GetTranslationEndpoint'))}
      sentryLogger={container.get<Logger>(fromStringToSymbol('SentryLogger'))}
    />,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
