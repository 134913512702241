import CreateEmployeeWhenAuthenticated from 'core/domain/model/employee/write/event/createEmployeeWhenAuthenticated/CreateEmployeeWhenAuthenticated';
import InventoryFinalizedListener from 'core/domain/model/inventory/write/event/inventoryFinalized/InventoryFinalizedListener';
import InventoryReferenceFailedListener from 'core/domain/model/inventory/write/event/inventoryReferenceFailed/InventoryReferenceFailedListener';
import InventoryRemovedListener from 'core/domain/model/inventory/write/event/inventoryRemoved/InventoryRemovedListener';
import OrderReturnFinishedListener from 'core/domain/model/orderReturn/write/event/orderReturnFinished/OrderReturnFinishedListener';
import OrdersRemovedListener from 'core/domain/model/pallet/write/event/ordersRemoved/OrdersRemovedListener';
import PalletLabelsPrintedListener from 'core/domain/model/pallet/write/event/palletLabelsPrinted/PalletLabelsPrintedListener';
import PalletSendFinishedListener from 'core/domain/model/pallet/write/event/palletSendFinished/PalletSendFinishedListener';
import PalletSendFinishFailedListener from 'core/domain/model/pallet/write/event/palletSendFinishFailed/PalletSendFinishFailedListener';
import PalletsSentListener from 'core/domain/model/pallet/write/event/palletsSent/PalletsSentListener';
import PrintPalletLabelsFailedListener from 'core/domain/model/pallet/write/event/printPalletLabelsFailed/PrintPalletLabelsFailedListener';
import SendPalletsFailedListener from 'core/domain/model/pallet/write/event/sendPalletsFailed/SendPalletsFailedListener';
import OrderReturnReferencePrintedListener from 'core/domain/model/printOrderReturnReference/write/event/orderReturnReferencePrinted/OrderReturnReferencePrintedListener';
import CachedRouteAssignedListener from 'core/domain/model/route/write/event/cachedRouteAssigned/CachedRouteAssignedListener';
import RouteAssignedToTrolleyListener from 'core/domain/model/route/write/event/routeAssignedToTrolley/RouteAssignedToTrolleyListener';
import RouteDocumentsPrintedListener from 'core/domain/model/route/write/event/routeDocumentsPrinted/RouteDocumentsPrintedListener';
import RoutesRemovedListener from 'core/domain/model/routeList/write/event/routesRemoved/RoutesRemovedListener';

const processManagerRegisterList = [
  {
    identifier: 'PalletLabelsPrintedListener',
    dependencies: [],
    classInjection: PalletLabelsPrintedListener,
  },
  {
    identifier: 'PalletsSentListener',
    dependencies: [],
    classInjection: PalletsSentListener,
  },
  {
    identifier: 'OrdersRemovedListener',
    dependencies: [],
    classInjection: OrdersRemovedListener,
  },
  {
    identifier: 'InventoryFinalizedListener',
    dependencies: [],
    classInjection: InventoryFinalizedListener,
  },
  {
    identifier: 'OrderReturnFinishedListener',
    dependencies: [],
    classInjection: OrderReturnFinishedListener,
  },
  {
    identifier: 'InventoryRemovedListener',
    dependencies: [],
    classInjection: InventoryRemovedListener,
  },
  {
    identifier: 'RoutesRemovedListener',
    dependencies: [],
    classInjection: RoutesRemovedListener,
  },
  {
    identifier: 'PrintPalletLabelsFailedListener',
    dependencies: [],
    classInjection: PrintPalletLabelsFailedListener,
  },
  {
    identifier: 'SendPalletsFailedListener',
    dependencies: [],
    classInjection: SendPalletsFailedListener,
  },
  {
    identifier: 'InventoryReferenceFailedListener',
    dependencies: [],
    classInjection: InventoryReferenceFailedListener,
  },
  {
    identifier: 'RouteDocumentsPrintedListener',
    dependencies: [],
    classInjection: RouteDocumentsPrintedListener,
  },
  {
    identifier: 'OrderReturnReferencePrintedListener',
    dependencies: [],
    classInjection: OrderReturnReferencePrintedListener,
  },
  {
    identifier: 'RouteAssignedToTrolleyListener',
    dependencies: [],
    classInjection: RouteAssignedToTrolleyListener,
  },
  {
    identifier: 'CachedRouteAssignedListener',
    dependencies: [],
    classInjection: CachedRouteAssignedListener,
  },
  {
    identifier: 'PalletSendFinishedListener',
    dependencies: [],
    classInjection: PalletSendFinishedListener,
  },
  {
    identifier: 'PalletSendFinishFailedListener',
    dependencies: [],
    classInjection: PalletSendFinishFailedListener,
  },
  {
    identifier: 'CreateEmployeeWhenAuthenticated',
    dependencies: [],
    classInjection: CreateEmployeeWhenAuthenticated,
  },
];

export { processManagerRegisterList };
