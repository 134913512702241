enum ErrorCode {
  ROUTE_INVALID = 'R-001',
  ROUTE_VIEW_NOT_FOUND = 'R-002',
  ASSING_PREPARER_TO_ROUTE_FAILED = 'R-003',
  ROUTE_NOT_FOUND_TO_PREPARE = 'R-004',
  ROUTE_WITH_TROLLEY = 'R-005',
  ROUTE_EXISTING_WITH_TROLLEY = 'R-006',
  ROUTE_NOT_PREPARED_TO_ASSIGN_TROLLEY = 'R-007',
  TROLLEY_BARCODE_BAD_FORMAT = 'R-008',
  ROUTE_NOT_FOUND_TO_ASSIGN_TROLLEY = 'R-009',
  ROUTE_NOT_FOUND_TO_PRINT = 'R-010',
  ROUTE_NOT_FOUND_TO_MANUAL_ASSIGN = 'R-011',
  CACHED_ROUTE_ASSIGNED_TO_PICKER = 'R-012',

  PALLET_ORDER_INVALID = 'P-0001',
  PALLET_ORDER_NOT_FOUND = 'P-0002',
  PALLET_ORDER_DUPLICATED = 'P-0003',

  INVENTORY_LOCATION_INVALID = 'I-0001',
  INVENTORY_REFERENCE_INVALID = 'I-0002',

  ORDER_REFERENCE_INVALID = 'O-0001',
  ORDER_VIEWS_NOT_FOUND = 'O-0002',

  FINISH_ORDER_RETURN = 'OR-0001',
  ORDER_RETURN_VIEW_NOT_FOUND = 'OR-0002',

  PRINT_ORDER_RETURN_REFERENCE_FAILED_ERROR = 'ORR-0001',
}

export default ErrorCode;
