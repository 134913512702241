import { Command, CommandHandler } from '@lookiero/messaging.js';
import { handlerContainer } from '../helpers/handlerContainer';
import { Container } from 'inversify';
import { registers } from '../helpers/registers';
import Class from '@lookiero/messaging.js/dist/Class';

import CreateEmployee from 'core/application/employee/create/CreateEmployee';
import LoginEmployee from 'core/application/employee/login/LoginEmployee';
import LogoutEmployee from 'core/application/employee/logout/LogoutEmployee';
import FinalizeInventory from 'core/application/inventory/finalizeInventory/FinalizeInventory';
import InventoryReference from 'core/application/inventory/inventoryReference/InventoryReference';
import RemoveInventory from 'core/application/inventory/removeInventory/RemoveInventory';
import CreateNotification from 'core/application/notification/createNotification/CreateNotification';
import RemoveNotification from 'core/application/notification/removeNotification/RemoveNotification';
import FinishOrderReturn from 'core/application/orderReturn/finishOrderReturn/FinishOrderReturn';
import PrintOrderReturnReference from 'core/application/orderReturnReference/printOrderReturnReference/PrintOrderReturnReference';
import AddOrder from 'core/application/pallet/addOrder/AddOrder';
import PrintLabel from 'core/application/pallet/printPallet/PrintLabel';
import RemoveOrders from 'core/application/pallet/removeOrders/RemoveOrders';
import SendFinishedPallet from 'core/application/pallet/sendFinishedPallet/SendFinishedPallet';
import SendPallets from 'core/application/pallet/sendPallet/SendPallets';
import AssignPreparerPrinter from 'core/application/printer/assignPreparerPrinter/AssignPreparerPrinter';
import AssignReturnsPrinter from 'core/application/printer/assignReturnsPrinter/AssignReturnsPrinter';
import AssignCachedRoute from 'core/application/route/assignCachedRoute/AssignCachedRoute';
import AssignPreparer from 'core/application/route/assignPreparer/AssignPreparer';
import AssignRouteToTrolley from 'core/application/route/assignRouteToTrolley/AssignRouteToTrolley';
import AssignRouteTrolleyToPicker from 'core/application/route/assignRouteTrolleyToPicker/AssignRouteTrolleyToPicker';
import PrintRouteDocuments from 'core/application/route/printRouteDocuments/PrintRouteDocuments';
import RemoveRoutes from 'core/application/routeList/removeRoutes/RemoveRoutes';
import ThrowError from 'shared/application/error/throwError/ThrowError';
import { commandRegisterList } from './commandRegisterList';

interface CommandDependeciesFunctionArgs {
  readonly container: Container;
}
type CommandDependeciesFunctionResult = [Class<Command>, CommandHandler<Command>][];

interface CommandDependeciesFunction {
  (args: CommandDependeciesFunctionArgs): Promise<CommandDependeciesFunctionResult>;
}

const commandDependecies: CommandDependeciesFunction = async ({ container }) => {
  const { listRegister } = registers({ container });
  const handler = handlerContainer({ container });

  listRegister(commandRegisterList);

  return Promise.resolve([
    [ThrowError, handler('ThrowCoreErrorHandler')],
    [LoginEmployee, handler('LoginEmployeeHandler')],
    [LogoutEmployee, handler('LogoutEmployeeHandler')],
    [PrintLabel, handler('PrintLabelHandler')],
    [AddOrder, handler('AddOrderHandler')],
    [RemoveOrders, handler('RemoveOrdersHandler')],
    [SendPallets, handler('SendPalletsHandler')],
    [InventoryReference, handler('InventoryReferenceHandler')],
    [RemoveInventory, handler('RemoveInventoryHandler')],
    [FinalizeInventory, handler('FinalizeInventoryHandler')],
    [FinishOrderReturn, handler('FinishOrderReturnHandler')],
    [RemoveRoutes, handler('RemoveRoutesHandler')],
    [AssignPreparer, handler('AssignPreparerHandler')],
    [AssignRouteToTrolley, handler('AssignRouteToTrolleyHandler')],
    [AssignCachedRoute, handler('AssignCachedRouteHandler')],
    [AssignRouteTrolleyToPicker, handler('AssignRouteTrolleyToPickerHandler')],
    [AssignPreparerPrinter, handler('AssignPreparerPrinterHandler')],
    [AssignReturnsPrinter, handler('AssignReturnsPrinterHandler')],
    [PrintRouteDocuments, handler('PrintRouteDocumentsHandler')],
    [PrintOrderReturnReference, handler('PrintOrderReturnReferenceHandler')],
    [CreateEmployee, handler('CreateEmployeeHandler')],
    [SendFinishedPallet, handler('SendFinishedPalletHandler')],
    [CreateNotification, handler('CreateNotificationHandler')],
    [RemoveNotification, handler('RemoveNotificationHandler')],
  ] as CommandDependeciesFunctionResult);
};

export { commandDependecies };
