import { DomainEvent, ProcessManager } from '@lookiero/messaging.js';
import { handlerContainer } from '../helpers/handlerContainer';
import { Container } from 'inversify';
import { registers } from '../helpers/registers';
import Class from '@lookiero/messaging.js/dist/Class';
import ErrorThrown from 'shared/domain/error/model/ErrorThrown';
import EmployeeAuthenticated from 'core/domain/model/employee/write/event/createEmployeeWhenAuthenticated/EmployeeAuthenticated';
import InventoryFinalized from 'core/domain/model/inventory/write/event/inventoryFinalized/InventoryFinalized';
import InventoryReferenceFailed from 'core/domain/model/inventory/write/event/inventoryReferenceFailed/InventoryReferenceFailed';
import InventoryRemoved from 'core/domain/model/inventory/write/event/inventoryRemoved/InventoryRemoved';
import OrderReturnFinished from 'core/domain/model/orderReturn/write/event/orderReturnFinished/OrderReturnFinished';
import OrdersRemoved from 'core/domain/model/pallet/write/event/ordersRemoved/OrdersRemoved';
import PalletLabelsPrinted from 'core/domain/model/pallet/write/event/palletLabelsPrinted/PalletLabelsPrinted';
import PalletSendFinished from 'core/domain/model/pallet/write/event/palletSendFinished/PalletSendFinished';
import PalletsSent from 'core/domain/model/pallet/write/event/palletsSent/PalletsSent';
import PrintPalletLabelsFailed from 'core/domain/model/pallet/write/event/printPalletLabelsFailed/PrintPalletLabelsFailed';
import SendPalletsFailed from 'core/domain/model/pallet/write/event/sendPalletsFailed/SendPalletsFailed';
import OrderReturnReferencePrinted from 'core/domain/model/printOrderReturnReference/write/event/orderReturnReferencePrinted/OrderReturnReferencePrinted';
import RouteAssignedToTrolley from 'core/domain/model/route/write/event/routeAssignedToTrolley/RouteAssignedToTrolley';
import RouteDocumentsPrinted from 'core/domain/model/route/write/event/routeDocumentsPrinted/RouteDocumentsPrinted';
import RoutesRemoved from 'core/domain/model/routeList/write/event/routesRemoved/RoutesRemoved';
import { processManagerRegisterList } from './processManagerRegisterList';
import CachedRouteAssigned from 'core/domain/model/route/write/event/cachedRouteAssigned/CachedRouteAssigned';

interface ProcessManagerDependeciesFunctionArgs {
  readonly container: Container;
}
type ProcessManagerDependeciesFunctionResult = [Class<DomainEvent>, ProcessManager<DomainEvent>][];

interface ProcessManagerDependeciesFunction {
  (args: ProcessManagerDependeciesFunctionArgs): Promise<ProcessManagerDependeciesFunctionResult>;
}

const processManagerDependecies: ProcessManagerDependeciesFunction = async ({ container }) => {
  const { listRegister } = registers({ container });
  const handler = handlerContainer({ container });

  listRegister(processManagerRegisterList);

  return Promise.resolve([
    [ErrorThrown, handler('SentryLogger')],
    [PalletLabelsPrinted, handler('PalletLabelsPrintedListener')],
    [PalletsSent, handler('PalletsSentListener')],
    [PalletSendFinished, handler('PalletSendFinishedListener')],
    [OrdersRemoved, handler('OrdersRemovedListener')],
    [InventoryFinalized, handler('InventoryFinalizedListener')],
    [OrderReturnFinished, handler('OrderReturnFinishedListener')],
    [InventoryRemoved, handler('InventoryRemovedListener')],
    [RoutesRemoved, handler('RoutesRemovedListener')],

    [PrintPalletLabelsFailed, handler('PrintPalletLabelsFailedListener')],
    [SendPalletsFailed, handler('SendPalletsFailedListener')],
    [InventoryReferenceFailed, handler('InventoryReferenceFailedListener')],

    [RouteDocumentsPrinted, handler('RouteDocumentsPrintedListener')],
    [OrderReturnReferencePrinted, handler('OrderReturnReferencePrintedListener')],
    [RouteAssignedToTrolley, handler('RouteAssignedToTrolleyListener')],
    [CachedRouteAssigned, handler('CachedRouteAssignedListener')],
    [EmployeeAuthenticated, handler('CreateEmployeeWhenAuthenticated')],
  ] as ProcessManagerDependeciesFunctionResult);
};

export { processManagerDependecies };
