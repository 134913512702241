import DomainError from 'shared/domain/error/DomainError';
import ErrorCode from 'shared/domain/error/ErrorCode';
import { I18N_MESSAGES } from 'shared/i18n/picking';

class CachedRouteAssignedToPickerError extends DomainError {
  public constructor() {
    super(
      ErrorCode.CACHED_ROUTE_ASSIGNED_TO_PICKER,
      I18N_MESSAGES.CACHED_ROUTE_ASSIGNED_TO_PICKER,
      CachedRouteAssignedToPickerError.name,
    );
  }
}

export default CachedRouteAssignedToPickerError;
